// we've historically used the modern convenience definition to display files sizes https://en.wikipedia.org/wiki/Megabyte#Definitions
export const BYTES_IN_MEGABYTE = 1000000;

// required or else I18n FormattedSize component will report as "1.9GB"
const BYTES_IN_MB_FOR_UPLOAD_LIMIT = 1024 * 1024;
// CF edge & internal limits will fail uploads when 2GB has been transferred
export const MAX_UPLOAD_SIZE_BYTES = 2 * 1000 * BYTES_IN_MB_FOR_UPLOAD_LIMIT;

// if a file is larger than this threshold, we'll use chunked uploads
export const LARGE_FILE_UPLOAD_SIZE_THRESHOLD = 100 * BYTES_IN_MB_FOR_UPLOAD_LIMIT;

// the size of each part of a large file upload
export const LARGE_FILE_UPLOAD_PART_SIZE = 20 * BYTES_IN_MB_FOR_UPLOAD_LIMIT;