import apiClient from 'hub-http/clients/apiClient';
import { buildFileFromAttrs } from 'FileManagerCore/api/Files';
export const setFileExpiration = (fileId, timestamp) => {
  const query = {
    timestamp // just placed in query for logging / auditing
  };
  return apiClient.post(`filemanager/api/v3/files/${fileId}/set-expires-at`, {
    data: timestamp,
    query
  }).then(buildFileFromAttrs);
};
export const removeFileExpiration = fileId => {
  return apiClient.post(`filemanager/api/v3/files/${fileId}/delete-expires-at`).then(buildFileFromAttrs);
};