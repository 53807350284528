import http from 'hub-http/clients/apiClient';
import { FOLDER_BY_PARENT_REQUEST_LIMIT } from '../constants/CrmPlatformLimits';
import pick from '../utils/pick';
import { ObjectCategory } from '../Constants';
const BASE_URI_V3 = 'filemanager/api/v3/folders';
const PUBLIC_SEARCH_URI = 'files-public-search/v1/search';
const PUBLIC_SEARCH_COUNT_URI = 'files-public-search/v1/count';
const DEFAULT_QUERY = {
  order_by: 'name',
  offset: 0,
  limit: FOLDER_BY_PARENT_REQUEST_LIMIT
};
const PASSTHROUGH_QUERY_PARAMS = ['order_by', 'offset', 'limit', 'parent_folder_id', 'hostApp', 'sourceGroups'];
const buildQueryObject = options => {
  const query = Object.assign({}, DEFAULT_QUERY, pick(options, PASSTHROUGH_QUERY_PARAMS));
  if (options.search) {
    query.name = options.search;
  }
  return query;
};
const translateLegacyQuery = query => {
  const crmSearchQuery = Object.assign({}, query, {
    category: ObjectCategory.FOLDER
  });
  if (query.parent_folder_id) {
    crmSearchQuery.folderId = query.parent_folder_id === 'None' ? 0 : Number(query.parent_folder_id);
  }
  return crmSearchQuery;
};
export const fetchFolderWithBreadcrumbs = folderIdOrPath => {
  return http.get(`${BASE_URI_V3}/breadcrumbs/${folderIdOrPath}`);
};
export const fetchFoldersFromPublicSearch = (options = {}) => http.get(PUBLIC_SEARCH_URI, {
  query: translateLegacyQuery(buildQueryObject(options))
});
export const fetchFoldersCountFromPublicSearch = (foldersList, sourceGroups) => http.get(PUBLIC_SEARCH_COUNT_URI, {
  query: {
    folderIds: foldersList,
    sourceGroups
  }
});