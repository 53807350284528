import { ObjectCategory } from '../Constants';
import { getCurrentUserTeams, getIsUserSuperAdmin } from '../selectors/Auth';
import { getSingleFileDetails } from '../selectors/FileDetails';
import { getFoldersById } from '../selectors/Folders';
export const getAssetHasTeam = (assetTeams, userTeams) => {
  if (!assetTeams) {
    return true;
  }
  return assetTeams.length === 0 || assetTeams.some(assetTeam => userTeams.includes(assetTeam));
};
const getSelectorForObject = category => category === ObjectCategory.FOLDER ? getFoldersById : getSingleFileDetails;
export const getUserHasAccessToAsset = (state, objectId, objectCategory) => {
  if (!objectId || getIsUserSuperAdmin(state)) {
    return true;
  }
  const objects = getSelectorForObject(objectCategory)(state);
  const object = objects.get(objectId);
  const objectTeams = object.get('teams') ? object.get('teams').toArray() : [];
  return getAssetHasTeam(objectTeams, getCurrentUserTeams(state).map(t => t.id));
};
export const flattenTeams = (team, path = [], result = []) => {
  if (!team.childTeams.length) {
    result.push(path.concat(team));
  }
  for (const child of team.childTeams) {
    flattenTeams(child, path.concat(team), result);
  }
  return result;
};