import { ProductFeatureIds } from '../constants/ProductFeatureIds';
import { reportMessage } from './logging';
export const getIsFileAiGenerated = file => file.getIn(['meta', 'ai_generated']);
export const getAdobeId = file => file.getIn(['meta', 'adobe_id']);
export const buildFullPrompt = (prompt, style) => {
  return style ? `${style}: ${prompt}` : prompt;
};
const FILE_NAME_SANITIZATION_REGEX = /[|/#!$%@^&*;:{}=\-_`~()]/g;
const FILE_NAME_MAX_LENGTH = 150;
export const getFileNameFromPrompt = prompt => {
  const sentences = prompt.split('.');
  if (sentences.length > 1 && sentences[0].length > 5 &&
  // exclude strings too short to likely be sentences
  sentences[0].length < FILE_NAME_MAX_LENGTH) {
    prompt = sentences[0];
  }
  return prompt.replace(FILE_NAME_SANITIZATION_REGEX, '').replace(/\s{2,}/g, ' ').trim().substring(0, FILE_NAME_MAX_LENGTH);
};

// Handles mapping the supported image prompt generation commands to their
// aiFeatureId. Note: most of these bubble up to their associated image generate
// feature, but can have distinct product feature identifiers
export const getImagePromptGenFeatureId = applicationId => {
  switch (applicationId) {
    case 'podcast-editor-ui':
      return ProductFeatureIds.GENERATE_IMAGE_PROMPT_PODCAST;
    case 'blog-editor-ui':
      return ProductFeatureIds.GENERATE_IMAGE_BLOG;
    case 'page-editor-ui':
      return ProductFeatureIds.GENERATE_IMAGE_WEBSITE_PAGE;
    default:
      reportMessage('Missing image prompt generation feature id for applicationId', {
        applicationId
      });
      return undefined;
  }
};

// Handles mapping our potential consumers with their associated image generation
// aiFeatureId. A full list of aiFeatureIds can be found here in the product catalog
// https://tools.hubteam.com/ai-feature-catalog/categories/generate_images
export const getImageGenFeatureId = applicationId => {
  switch (applicationId) {
    case 'blog-editor-ui':
      return ProductFeatureIds.GENERATE_IMAGE_BLOG;
    case 'page-editor-ui':
      return ProductFeatureIds.GENERATE_IMAGE_WEBSITE_PAGE;
    case 'EmailDragDropEditorUI':
      return ProductFeatureIds.GENERATE_IMAGE_MARKETING_EMAIL;
    case 'file-manager-demo':
      return ProductFeatureIds.INTERNAL;
    case 'podcast-editor-ui':
    case 'podcast-settings-ui':
      return ProductFeatureIds.GENERATE_IMAGE_PODCAST;
    case 'SocialUI':
      return ProductFeatureIds.GENERATE_IMAGE_SOCIAL;
    case 'vis-exp-editor-forms':
      return ProductFeatureIds.GENERATE_IMAGE_FORMS;
    case 'web-interactives-editor-ui':
      return ProductFeatureIds.GENERATE_IMAGE_CTA;
    // a feature id has not been assigned for this application
    case 'EmailTemplateEditorUI':
    default:
      reportMessage('Missing image generation feature id for applicationId', {
        applicationId
      });
      return undefined;
  }
};