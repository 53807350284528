import { createSelector } from 'reselect';
import { RequestStatus } from '../Constants';
export const getSubtitlesState = state => state.videoSubtitles;
export const selectIsSubtitleUploadPending = createSelector([getSubtitlesState], subtitles => subtitles.get('updateSubtitlesRequestStatus') === RequestStatus.PENDING);
export function getSubtitles(state) {
  return state.videoSubtitles.get('locallyLoadedSubtitles');
}
export function getSubtitlesByVideoFileId(state, videoFileId) {
  return state.videoSubtitles.getIn(['locallyLoadedSubtitles', videoFileId]);
}
export const selectFetchGenerationOptInRequestStatus = createSelector([getSubtitlesState], subtitlesState => subtitlesState.get('fetchGenerationOptInRequestStatus'));
export const selectIsSubtitleGenerationEnabled = createSelector([getSubtitlesState], subtitlesState => subtitlesState.get('generationEnabled'));