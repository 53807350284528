import http from 'hub-http/clients/apiClient';
import pick from '../utils/pick';
import { ObjectCategory } from '../Constants';
export const PUBLIC_SEARCH_BASE_URI = 'files-public-search/v1/search';
const DEFAULT_QUERY = {
  category: ObjectCategory.FILE
};
export const translateLegacyOptions = (legacyOptions, category) => {
  const options = Object.assign({
    category
  }, pick(legacyOptions, ['archived', 'limit', 'offset', 'extension', 'sourceGroups']));
  if ('type' in legacyOptions && legacyOptions.type) {
    options.fileType = legacyOptions.type;
  }
  if (legacyOptions.search) {
    options.name = legacyOptions.search;
  } else {
    if ('folder_id' in legacyOptions && legacyOptions.folder_id) {
      options.folderId = legacyOptions.folder_id === 'None' ? 0 : Number(legacyOptions.folder_id);
    }
  }
  if (legacyOptions.order_by) {
    options.orderBy = legacyOptions.order_by.replace('updated', 'updatedAt');
  }
  if (legacyOptions.isHublVideo !== undefined) {
    options.isHublVideo = legacyOptions.isHublVideo;
  }
  return options;
};
export const fetchFilesFromPublicSearch = (legacyOptions, useSemanticSearch) => {
  const options = translateLegacyOptions(legacyOptions, ObjectCategory.FILE);
  const query = Object.assign({}, DEFAULT_QUERY, options, {
    semanticSearchEnabled: useSemanticSearch
  });
  return http.get(PUBLIC_SEARCH_BASE_URI, {
    query
  });
};