import http from 'hub-http/clients/apiClient';
import { buildFolderFromAttrs } from '../utils/FoldersAndFiles';
const BASE_URI = 'filemanager/api/v2/folders';
export function remove(folderId) {
  return http.delete(`${BASE_URI}/${folderId}`);
}
export function rename(folderId, newName) {
  const URI = `${BASE_URI}/${folderId}/move-folder`;
  return http.post(URI, {
    data: {
      name: newName
    }
  }).then(buildFolderFromAttrs);
}

// null `parentId` moves folder to top-level
export function move(folderId, parentId) {
  const URI = `${BASE_URI}/${folderId}/move-folder`;
  return http.post(URI, {
    data: {
      parent_folder_id: parentId
    }
  }).then(buildFolderFromAttrs);
}
export function add({
  name,
  parentId,
  sourceGroup
}) {
  const folderData = Object.assign({
    name,
    parent_folder_id: parentId
  }, sourceGroup && {
    sourceGroup
  });
  return http.post(BASE_URI, {
    data: folderData
  }).then(buildFolderFromAttrs);
}