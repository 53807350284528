import * as AspectRatioRoundingModes from '../constants/AspectRatioRoundingModes';
import I18n from 'I18n';
export function getCenterPixelValue(containerDimension, cropBoxDimension) {
  return containerDimension === cropBoxDimension ? 0 : (containerDimension - cropBoxDimension) / 2;
}
export function getCropBoxDataObjWithSmallerWidth(cropBoxData, containerData, newWidth, resizeRatio) {
  const resizedWidth = newWidth * resizeRatio;
  return Object.assign({}, cropBoxData, {
    width: resizedWidth,
    left: getCenterPixelValue(containerData.width, resizedWidth)
  });
}
export function getCropBoxDataObjWithSmallerHeight(cropBoxData, containerData, newHeight, resizeRatio) {
  const resizedHeight = newHeight * resizeRatio;
  return Object.assign({}, cropBoxData, {
    height: resizedHeight,
    top: getCenterPixelValue(containerData.height, resizedHeight)
  });
}

// TODO: Maybe instead of using cropper directly just pass in all the data you get from it? That way we can test it
export function getCropBoxDataObjForSquareImage(cropBoxData, containerData, canvasData, height, width) {
  if (height > width) {
    // Width would be the new size of everything, center height
    const resizedWidth = width * (canvasData.width / canvasData.naturalWidth);
    return Object.assign({}, cropBoxData, {
      height: resizedWidth,
      width: resizedWidth,
      top: getCenterPixelValue(containerData.height, resizedWidth)
    });
  } else {
    // Height would be the new size of everything, center width
    const resizedHeight = height * (canvasData.height / canvasData.naturalHeight);
    return Object.assign({}, cropBoxData, {
      height: resizedHeight,
      width: resizedHeight,
      left: getCenterPixelValue(containerData.width, resizedHeight)
    });
  }
}
function isRespectingAspectRatio(width, height, targetAspectRatio, mode) {
  const potentialNewAspectRatio = width / height;
  switch (mode) {
    case AspectRatioRoundingModes.ABOVE:
      return potentialNewAspectRatio >= targetAspectRatio;
    case AspectRatioRoundingModes.BELOW:
      return potentialNewAspectRatio <= targetAspectRatio;
    default:
      return true;
  }
}
export function getRectangularAdjustCropBoxParams(imgWidth, imgHeight, horizontal, vertical, mode = AspectRatioRoundingModes.DEFAULT) {
  let potentialNewWidth = Math.round(imgHeight / vertical * horizontal);
  let potentialNewHeight = Math.round(imgWidth / horizontal * vertical);
  const targetAspectRatio = horizontal / vertical;
  while (!isRespectingAspectRatio(imgWidth, potentialNewHeight, targetAspectRatio, mode)) {
    if (mode === AspectRatioRoundingModes.ABOVE) {
      if (potentialNewHeight <= 1) {
        break;
      }
      potentialNewHeight--;
    }
    if (mode === AspectRatioRoundingModes.BELOW) {
      if (potentialNewHeight >= imgHeight) {
        break;
      }
      potentialNewHeight++;
    }
  }
  while (!isRespectingAspectRatio(potentialNewWidth, imgHeight, targetAspectRatio, mode)) {
    if (mode === AspectRatioRoundingModes.ABOVE) {
      if (potentialNewWidth >= imgWidth) {
        break;
      }
      potentialNewWidth++;
    }
    if (mode === AspectRatioRoundingModes.BELOW) {
      if (potentialNewWidth <= 1) {
        break;
      }
      potentialNewWidth--;
    }
  }
  return {
    imgWidth,
    imgHeight,
    potentialNewWidth,
    potentialNewHeight
  };
}
export function getNewCanvasAndBoxDataAfterRotation(containerData, cropBoxData, canvasData) {
  const containerHeight = containerData.height;
  const containerWidth = containerData.width;
  const canvasHeight = canvasData.height;
  const canvasWidth = canvasData.width;
  let newHeight = 0;
  let newWidth = 0;

  // Use math round to compare integers instead of floats
  if (Math.round(canvasHeight) > Math.round(canvasWidth)) {
    const ratio = containerHeight / canvasHeight;
    newWidth = ratio * canvasWidth;
    if (newWidth >= containerWidth) {
      newWidth = containerWidth;
      newHeight = containerWidth / canvasWidth * canvasHeight;
      const newTop = (containerHeight - newHeight) / 2;
      canvasData.top = newTop;
      cropBoxData.top = newTop;
      canvasData.left = 0;
      cropBoxData.left = 0;
    } else {
      newHeight = containerHeight;
      const newLeft = (containerWidth - newWidth) / 2;
      canvasData.top = 0;
      canvasData.left = newLeft;
      cropBoxData.left = newLeft;
    }
  } else {
    const ratio = containerWidth / canvasWidth;
    newHeight = ratio * canvasHeight;
    if (newHeight >= containerHeight) {
      newHeight = containerHeight;
      newWidth = containerHeight / canvasHeight * canvasWidth;
      const newLeft = (containerWidth - newWidth) / 2;
      canvasData.left = newLeft;
      cropBoxData.left = newLeft;
      canvasData.top = 0;
      cropBoxData.top = 0;
    } else {
      newWidth = containerWidth;
      const newTop = (containerHeight - newHeight) / 2;
      canvasData.left = 0;
      canvasData.top = newTop;
      cropBoxData.top = newTop;
    }
  }
  canvasData.height = newHeight;
  canvasData.width = newWidth;
  cropBoxData.height = newHeight;
  cropBoxData.width = newWidth;
  return {
    canvasData,
    cropBoxData
  };
}
export function getCropBoxDataForDimensionAndPositionReset(containerData, canvasData) {
  const {
    height: containerHeight,
    width: containerWidth
  } = containerData;
  const {
    height: canvasHeight,
    width: canvasWidth
  } = canvasData;
  const top = canvasHeight === containerHeight ? 0 : (containerHeight - canvasHeight) / 2;
  const left = canvasWidth === containerWidth ? 0 : (containerWidth - canvasWidth) / 2;
  return {
    top,
    left,
    height: canvasHeight,
    width: canvasWidth
  };
}
export const getDefaultEditedImageName = () => I18n.text('FileManagerCore.imageEditor.defaultFileName');
export const getHasCropperAreaChanged = (cropper, cropperHeight, cropperWidth) => {
  var _cropper$initialImage, _cropper$initialImage2;
  const cropperWidthInt = parseInt(cropperWidth, 10);
  const cropperHeightInt = parseInt(cropperHeight, 10);
  const isInitialState = cropperWidthInt === 0 && cropperHeightInt === 0;
  return !isInitialState && (((_cropper$initialImage = cropper.initialImageData) === null || _cropper$initialImage === void 0 ? void 0 : _cropper$initialImage.naturalWidth) !== cropperWidthInt || ((_cropper$initialImage2 = cropper.initialImageData) === null || _cropper$initialImage2 === void 0 ? void 0 : _cropper$initialImage2.naturalHeight) !== cropperHeightInt);
};