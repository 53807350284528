'use es6';

import { ROOT_FOLDER_ID } from 'FileManagerCore/Constants';
import { MAX_SEARCH_QUERY_LENGTH } from 'FileManagerCore/constants/CrmPlatformLimits';
import { CHANGE_TAB } from 'FileManagerCore/actions/ActionTypes';
import { fetchFoldersByParentId, searchFolders } from 'FileManagerCore/actions/FolderFetch';
import { getTrackingMeta } from 'FileManagerCore/actions/tracking';
import { fetchSingleFileIfNeeded } from 'FileManagerCore/actions/FileDetails';
import { getFilesQuery } from 'FileManagerCore/selectors/Files';
import { fetchFolderByIdWithBreadcrumbs } from 'FileManagerCore/actions/FolderFetch';
import { selectIsUngatedForSourceGroups } from 'FileManagerCore/selectors/Auth';
import * as DashboardActionTypes from './ActionTypes';
import { searchFilesAndFolders } from 'FileManagerCore/actions/FilesAndFoldersFetch';
import { getFilesAndFoldersQueryFromOptions } from '../utils/getFilesAndFoldersQueryFromOptions';
import { READ_SOURCE_GROUPS } from '../Constants';
export function resetPage() {
  return {
    type: DashboardActionTypes.RESET_PAGE
  };
}
export function search(keywords) {
  return (dispatch, getState) => {
    const currentSearch = getFilesQuery(getState());
    keywords = keywords.substr(0, MAX_SEARCH_QUERY_LENGTH);
    if (currentSearch.get('search') === keywords && !currentSearch.get('extension')) {
      return;
    }
    dispatch(searchFolders(keywords, Object.assign({}, selectIsUngatedForSourceGroups(getState()) && {
      sourceGroups: READ_SOURCE_GROUPS
    })));
    dispatch(resetPage());
    dispatch({
      type: DashboardActionTypes.SEARCH,
      meta: getTrackingMeta('fileManagerExploreFiles', 'search'),
      search: keywords
    });
  };
}
export function archive(archived) {
  return dispatch => {
    dispatch(resetPage());
    dispatch({
      type: DashboardActionTypes.ARCHIVE,
      meta: getTrackingMeta('fileManagerExploreFiles', 'filter archived'),
      archived
    });
  };
}
export function filterFileType(fileType, isHublVideo) {
  return dispatch => {
    dispatch(resetPage());
    dispatch({
      type: DashboardActionTypes.FILTER_FILE_TYPE,
      meta: getTrackingMeta('fileManagerExploreFiles', 'filter file type'),
      fileType,
      isHublVideo
    });
  };
}
export function navigate(location, replace = false) {
  return {
    type: DashboardActionTypes.NAVIGATE,
    meta: getTrackingMeta('fileManagerExploreFiles', 'navigate'),
    location,
    replace
  };
}
export const changeTab = (tab, searchQuery) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_TAB,
    meta: getTrackingMeta('fileManagerExploreFiles', 'change tab', {
      tab
    }),
    tab,
    searchQuery
  });
  dispatch(fetchFoldersByParentId(ROOT_FOLDER_ID, Object.assign({}, selectIsUngatedForSourceGroups(getState()) && {
    sourceGroups: READ_SOURCE_GROUPS
  })));
};
export function changeSort(options) {
  return Object.assign({
    type: DashboardActionTypes.SORT,
    meta: getTrackingMeta('fileManagerExploreFiles', `change sort - ${options.orderBy}`)
  }, options);
}
export const changePage = options => (dispatch, getState) => {
  dispatch({
    type: DashboardActionTypes.CHANGE_PAGE,
    page: options.page
  });
  const queryFromOptions = getFilesAndFoldersQueryFromOptions(options, getState());
  if (!queryFromOptions) {
    return;
  }
  const query = Object.assign({}, queryFromOptions, selectIsUngatedForSourceGroups(getState()) && {
    sourceGroups: READ_SOURCE_GROUPS
  });
  dispatch(searchFilesAndFolders(query, {
    initialFetch: false,
    fetchingMore: true
  }));
};
export function selectFile(file) {
  return dispatch => {
    const usageAction = file ? 'view-detail' : 'close-detail';
    dispatch({
      type: DashboardActionTypes.SELECT_FILE,
      meta: getTrackingMeta('fileManagerExploreFiles', usageAction),
      file
    });
    if (file && file.get('folder_id')) {
      dispatch(fetchFolderByIdWithBreadcrumbs(file.get('folder_id')));
    }
  };
}
export const openFileDetailsPanel = fileId => dispatch => {
  dispatch(fetchSingleFileIfNeeded({
    fileId
  })).then(singleFile => {
    dispatch(selectFile(singleFile));
  }).catch(() => dispatch(selectFile(null)));
};
export function openModal(modalId, payload = {}) {
  return {
    type: DashboardActionTypes.OPEN_MODAL,
    meta: getTrackingMeta('fileManagerExploreFiles', `open modal ${modalId.toLowerCase()}`),
    modalId,
    payload
  };
}
export function closeModal() {
  return {
    type: DashboardActionTypes.CLOSE_MODAL
  };
}
export const setPageSize = pageSize => ({
  type: DashboardActionTypes.SET_PAGE_SIZE,
  pageSize
});