import http from 'hub-http/clients/apiClient';
import { createRpcClientV2 } from 'rpc-client-utils';
import { checkout as createCheckoutUrl } from 'self-service-api/core/utilities/links';
import { createPurchaseOrderRpc } from '../../../__generated__/chirp/com/hubspot/monetization/rpc/PurchaseChirpService';
import logPurchaseOrderSentry from '../../_core/common/reliability/logCheckoutError';
import { trackPurchaseOrderGenerated, trackPurchaseOrderGenerationFailed } from './buy-now-trackers';
const rpcClient = createRpcClientV2({
  hubHttpClient: http
});
export const generateCheckoutUrl = (requestedProducts, terms, buyNowUsageTrackingProperties) => {
  return rpcClient.call(createPurchaseOrderRpc, {
    request: {
      commitmentTerm: terms === null || terms === void 0 ? void 0 : terms.commitmentTerm,
      paymentTerm: terms === null || terms === void 0 ? void 0 : terms.paymentTerm,
      products: requestedProducts.map(({
        productApiName,
        quantity,
        quantityPacks
      }) => ({
        productApiName,
        quantity: quantity || 1,
        quantityPacks: quantityPacks || []
      }))
    }
  }).then(purchaseOrderResponse => {
    let upgradeSource;
    if (buyNowUsageTrackingProperties) {
      trackPurchaseOrderGenerated(buyNowUsageTrackingProperties);
      upgradeSource = buyNowUsageTrackingProperties.upgradeSource;
    }
    return createCheckoutUrl(purchaseOrderResponse.purchaseOrderId.toString(), upgradeSource);
  }, error => {
    console.error(error);
    const apiNames = requestedProducts.map(({
      productApiName
    }) => productApiName);
    const sentryDetails = Object.assign({
      apiNames,
      buttonName: 'UIBuyNowButton'
    }, buyNowUsageTrackingProperties);
    if (buyNowUsageTrackingProperties) {
      trackPurchaseOrderGenerationFailed(buyNowUsageTrackingProperties);
    }
    logPurchaseOrderSentry('generatePurchaseOrder', error, sentryDetails);
    throw error;
  });
};