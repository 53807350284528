import { searchFilesAndFolders } from 'FileManagerCore/actions/FilesAndFoldersFetch';
import { getFilesAndFoldersQueryFromOptions } from '../utils/getFilesAndFoldersQueryFromOptions';
import { MAX_OBJECT_REQUEST_LIMIT } from 'FileManagerCore/constants/CrmPlatformLimits';
import { selectIsUngatedForSourceGroups } from 'FileManagerCore/selectors/Auth';
// @ts-expect-error Not converted yet
import { navigate } from './Actions';
import { READ_SOURCE_GROUPS } from '../Constants';
export const fetchFilesAndFolders = (options, initialFetch = false) => (dispatch, getState) => {
  const queryFromOptions = getFilesAndFoldersQueryFromOptions(options, getState(), initialFetch);
  if (!queryFromOptions) {
    return;
  }
  const query = Object.assign({}, queryFromOptions, selectIsUngatedForSourceGroups(getState()) && {
    sourceGroups: READ_SOURCE_GROUPS
  });

  // If for some reason we're trying to fetch too many objects, revert to page 1
  // This can happen when navigating back through page history, for example
  if (query.limit > MAX_OBJECT_REQUEST_LIMIT) {
    dispatch(navigate(Object.assign({}, options, {
      page: 1
    }), true));
    query.limit = MAX_OBJECT_REQUEST_LIMIT;
  }
  return dispatch(searchFilesAndFolders(query, {
    initialFetch,
    currentFolderId: options.folderId
  }));
};